import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'

Vue.config.productionTip = false

const ax = Axios
ax.defaults.baseURL = 'https://api.reeqzan.com'

Vue.prototype.$axios = ax

new Vue({
  render: h => h(App),
}).$mount('#app')
