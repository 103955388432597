<template>
  <div id="app">
    <input type="file">
    <div>{{ title }}</div>
    <div class="global-section" :style="{ maxHeight: showGlobal ? 'unset' : '30px' }">
      <div class="title">
        Global Values
        <img class="expand-img" src="https://img.icons8.com/plumpy/24/000000/expand-arrow.png" :style="{ transform: `scaleY(${showGlobal ? '-1' : '1'})` }"
          @click="showGlobal ? showGlobal = false : showGlobal = true" />
      </div>
      <div class="global-variable" v-for="(g, globIndex) in globalVariables" :key="globIndex">
        <div class="key">{{ g['key'] }}</div>
        <div>:</div>
        <input type="text" v-model="g['value']">
      </div>
    </div>
    <div v-for="(group, gIndex) in items" :key="gIndex" class="item1" :style="{ maxHeight: hideDetails[gIndex]['show'] ? 'unset' : '30px' }">
      <div class="title">
        {{ group['name'] }}
        <img class="expand-img" src="https://img.icons8.com/plumpy/24/000000/expand-arrow.png" :style="{ transform: `scaleY(${hideDetails[gIndex]['show'] ? '-1' : '1'})` }"
          @click="hideDetails[gIndex]['show'] ? hideDetails[gIndex]['show'] = false : hideDetails[gIndex]['show'] = true" />
      </div>
      <div class="item2" v-for="(gItem, iIndex) in group['item']" :key="iIndex" :style="{ border: `1px solid ${getColor(gItem['request']['method'], 1)}`,
        backgroundColor: `${getColor(gItem['request']['method'], 0.15)}`, maxHeight: hideDetails[gIndex]['subItems'][iIndex]['show'] ? 'unset' : '30px' }">
        <div class="method-row">
          <div class="method" :style="{ backgroundColor: `${getColor(gItem['request']['method'], 1)}` }">{{ gItem['request']['method'] }}</div>
          <div class="url-path">/{{ gItem['request']['url']['path'].join('/') }}</div>
          <div class="description">{{ gItem['request']['description'] }}</div>
          <button @click="tryClicked(gItem['request'])">Try Me</button>
          <img class="expand-img" src="https://img.icons8.com/plumpy/24/000000/expand-arrow.png" :style="{ transform: `scaleY(${hideDetails[gIndex]['subItems'][iIndex]['show'] ? '-1' : '1'})` }"
            @click="hideDetails[gIndex]['subItems'][iIndex]['show'] ? hideDetails[gIndex]['subItems'][iIndex]['show'] = false : hideDetails[gIndex]['subItems'][iIndex]['show'] = true" />
        </div>
        <div class="method-options">
          <div class="title" style="background-color: white">Request</div>
          <div class="sub-options">
            <div class="title">Header</div>
            <div v-for="(header, hIndex) in gItem['request']['header']" :key="hIndex">
              {{ header['key'] }}: {{ header['value'] }}
            </div>
          </div>
          <div class="sub-options" v-if="gItem['request']['body']">
            <div class="title">Body</div>
            <div class="body-object">{{ gItem['request']['body'][gItem['request']['body']['mode']] }}</div>
          </div>
        </div>
        <div class="method-options">
          <div class="title" style="background-color: white">Response</div>
          <div class="sub-options" v-for="(resp, rIndex) in gItem['response']" :key="rIndex">
            <div class="title">{{ resp['name'] }} ({{ resp['status'] }} {{ resp['code'] }})</div>
            <div class="body-object">{{ resp['body'] }}</div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="showTest" class="test-background" @click="showTest = false"></div>
    </transition>
    <transition name="slide">
      <div v-if="showTest" class="test-container">
        <div style="font-weight: bold; margin: 5px 0">
          Method: <span :style="{ backgroundColor: getColor(selectedTest['method'], 1), color: 'white', padding: '5px' }">{{ selectedTest['method'] }}</span>
          <!-- <select style="margin-left: 5px; padding: 5px" v-model="selectedTest['scheme']">
            <option value="https://">HTTPS</option>
            <option value="http://">HTTP</option>
          </select> -->
        </div>
        <b>URL</b><input type="text" v-model="selectedTest['urlFormatted']">
        <b>Header</b><textarea class="body-text-area" style="max-height: 100px; min-height: 100px" v-model="selectedTest['headerFormatted']"></textarea>
        <b>Body</b><textarea class="body-text-area" v-model="selectedTest['body'][selectedTest['body']['mode']]"></textarea>
        <b>Response</b><textarea id="responseText" class="body-text-area" style="height: 100%; max-height: unset"></textarea>
        <button class="exec-btn" @click="execClicked" :disabled="isLoading">
          {{ isLoading ? 'Executing' : 'Execute' }}
          <div v-if="isLoading" class="spinner"></div>
        </button>
        <img class="close-btn" @click="showTest = !showTest" src="https://img.icons8.com/dusk/64/000000/close-window.png"/>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable */
import apiJson from './dsmapi.json';

export default {
  name: 'App',
  data() {
    return {
      title: '',
      items: [],

      showTest: false,
      selectedTest: null,

      globalVariables: [],
      showGlobal: true,

      hideDetails: [],

      isLoading: false,

      colors: [
        { 'key': 'POST', 'color': '73,204,144' },
        { 'key': 'PUT', 'color': '252,161,48' },
        { 'key': 'GET', 'color': '97,175,254' },
        { 'key': 'DELETE', 'color': '249,62,62' }
      ]
    }
  },
  components: {
  },
  methods: {
    getColor: function(method, opac) {
      var color = this.colors.filter((c) => { return c['key'].toLowerCase().trim() == method.toLowerCase().trim() });
      if (color.length == 0) {
        return `rgba(0,0,255,${opac})`;
      } else {
        return `rgba(${color[0]['color']},${opac})`;
      }
    },
    tryClicked: function(e) {
      this.showTest = true;
      e['scheme'] = 'https://';
      console.log(e);

      e['urlFormatted'] = `${e['url']['protocol']}://`;
      var newHost = [];
      e['url']['host'].forEach((h) => {
        if (h.includes('{{')) {
          var thisHost = h;
          thisHost = thisHost.replace('{{', '');
          thisHost = thisHost.replace('}}', '');

          var headerValue = this.globalVariables.filter((g) => { return g['key'].trim() == thisHost.trim() });
          if (headerValue.length > 0) {
            if (headerValue[0]['value']) {
              thisHost = headerValue[0]['value'];
            } else {
              thisHost = `-${thisHost} NOT SET-`;
            }
          } else {
            thisHost = `-${thisHost} NOT FOUND-`;
          }

          newHost.push(thisHost);
        } else {
          newHost.push(h);
        }
      });
      e['urlFormatted'] += newHost.join('.');
      if (e['url']['port']) {
        e['urlFormatted'] += `:${e['url']['port']}`;
      }
      e['urlFormatted'] += '/';
      var newPath = [];
      e['url']['path'].forEach((h) => {
        if (h.includes('{{')) {
          var thisPath = h;
          thisPath = thisPath.replace('{{', '');
          thisPath = thisPath.replace('}}', '');

          var headerValue = this.globalVariables.filter((g) => { return g['key'].trim() == thisPath.trim() });
          if (headerValue.length > 0) {
            if (headerValue[0]['value']) {
              thisPath = headerValue[0]['value'];
            } else {
              thisPath = `-${thisPath} NOT SET-`;
            }
          } else {
            thisPath = `-${thisPath} NOT FOUND-`;
          }

          newPath.push(thisPath);
        } else {
          newPath.push(h);
        }
      });
      e['urlFormatted'] += newPath.join('/');

      e['headerFormatted'] = '';
      e['header'].forEach((h) => {
        var newValue = '';
        if (h['value'].includes('{{')) {
          newValue = h['value'];
          newValue = newValue.replace('{{', '');
          newValue = newValue.replace('}}', '');

          var headerValue = this.globalVariables.filter((g) => { return g['key'].trim() == newValue.trim() });
          if (headerValue.length > 0) {
            if (headerValue[0]['value']) {
              newValue = headerValue[0]['value'];
            } else {
              newValue = `-${newValue} NOT SET-`;
            }
          } else {
            newValue = `-${newValue} NOT FOUND-`;
          }
        } else {
          newValue = h['value'];
        }
        e['headerFormatted'] += `${h['key']}: ${newValue}\n`;
      });

      e['response'] = '';
      this.selectedTest = e;
    },
    execClicked: async function() {
      var data = this.selectedTest['body'][this.selectedTest['body']['mode']];
      var headers = this.selectedTest['headerFormatted'].split('\n').filter((h) => { return h });
      
      var dataToPost = {
        'url': this.selectedTest['urlFormatted'],
        'header': headers,
        'data': JSON.parse(data)
      };

      this.isLoading = true;
      var response = await this.$axios.post(`/Request/SendRequest?method=${this.selectedTest['method']}`, dataToPost);
      this.isLoading = false;
      var responseStr = JSON.stringify(response.data);
      responseStr = responseStr.split('\\"').join('"');
      responseStr = responseStr.split('"[').join('[');
      responseStr = responseStr.split(']"').join(']');
      responseStr = responseStr.split('"{').join('{');
      responseStr = responseStr.split('}"').join('}');
      document.getElementById('responseText').innerHTML = JSON.stringify(JSON.parse(responseStr), null, '  ');
    }
  },
  mounted() {
    this.title = apiJson['info']['name'];
    this.items = apiJson['item'];

    this.items.forEach((i) => {
      var subItemsDetails = [];
      var subItems = i['item'];
      subItems.forEach(() => {
        subItemsDetails.push({ show: false });
      });

      this.hideDetails.push({ show: false, subItems: subItemsDetails });
    });

    var allGlobal = [];
    var str = JSON.stringify(apiJson);
    var firstSplit = str.split('{{');
    firstSplit.forEach((f) => {
      if (f.includes('}}')) {
        if (f.split('}}')[0].length < 30)
        allGlobal.push(f.split('}}')[0]);
      }
    });

    var unique = [...new Set(allGlobal)];
    unique.forEach((u) => {
      this.globalVariables.push({
        key: u,
        value: u == 'API_Key' ? 'A7C71134-CA90-485A-B377-F4E8861A247F' : u == 'Config_Code' ? 'dsm6g' : u == 'IP' ? 'dsmapi.logistics.systems' : ''
        // value: ''
      });
    });
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}

#app {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.title {
  height: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  position: relative;

  > .expand-img {
    height: 16px;
    position: absolute;
    right: 5px;
    transition: 0.3s;
    cursor: pointer;
  }
}

.global-section {
  width: 95%;
  max-width: 80%;
  border: 1px solid gray;
  padding: 5px;
  overflow: hidden;
  
  > .global-variable {
    display: flex;
    margin: 5px;
    align-items: center;

    > .key {
      min-width: 200px;
    }

    > input {
      margin-left: 5px;
      padding: 5px;
    }
  }
}

.item1 {
  border: 1px solid gray;
  box-shadow: 1px 1px 5px gray;
  margin: 5px 0;
  padding: 5px;
  width: 95%;
  max-width: 80%;
  overflow: hidden;

  > .item1-title {
    height: 30px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
  }

  > .item2 {
    margin-left: 5px;
    margin-top: 5px;
    padding: 5px;
    margin-bottom: 5px;
    background-color: rgba(0, 0, 255, 0.05);
    overflow: hidden;

    > .method-row {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      position: relative;
      padding-right: 80px;
      
      > .expand-img {
        height: 16px;
        position: absolute;
        right: 5px;
        transition: 0.3s;
        cursor: pointer;
      }

      > button {
        background-color: transparent;
        border-radius: 3px;
        border: 2px solid gray;
        padding: 3px;
        position: absolute;
        right: 30px;
        cursor: pointer;
        transition: 0.3s;

        &:focus {
          outline: none;
        }

        &:hover {
          box-shadow: 1px 1px 5px gray;
          font-weight: bold;
        }
      }

      > .method {
        min-width: 60px;
        color: white;
        font-weight: bolder;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
      }

      > .url-path {
        // min-width: 150px;
        /* width: calc(100% - 65px); */
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
      }

      > .description {
        font-size: 0.8em;
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        width: calc(100% - 320px);
        text-overflow: ellipsis;
      }
    }

    > .method-options {
      > .sub-options {
        border: 1px solid gray;
        padding: 5px;
        margin-bottom: 3px;

        > .body-object {
          white-space: pre;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.test-background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(128,128,128,0.5);
  z-index: 255;
}

.test-container {
  position: fixed;
  width: 90%;
  max-width: 500px;
  height: 100%;
  right: 0;
  top: 0;
  background-color: white;
  z-index: 257;
  padding: 5px;
  display: flex;
  flex-direction: column;

  > * {
    box-sizing: border-box;
  }

  > .body-text-area {
    min-height: 200px;
    max-height: 200px;
    resize: none;
  }

  > textarea, > input {
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    border: 1px solid gray;
    
    &:focus {
      outline: none;
    }
  }
  
  > .exec-btn {
    margin-bottom: 10px;
    background-color: rgb(137, 159, 255);
    border: 1px solid gray;
    padding: 5px 0;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    
    > .spinner {
      height: 20px;
      width: 20px;
      border-width: 2px;
      border-color: transparent red transparent blue;
      border-style: solid;
      border-radius: 100%;
      margin-left: 5px;
      transition: 0.3s;
      animation: spin 1s ease-in-out infinite;
    }
  }

  > .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 25px;
    cursor: pointer;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition: all .3s ease;
}
.slide-leave-active {
  transition: all .3s ease;
}
.slide-enter, .slide-leave-to {
  transform: translateX(100%);
}
</style>
